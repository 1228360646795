// 条件类型
export const getConditionTypeList = ({roleList, userList, workerList, orgList, dataSourceKeys}) => {
  return [{
    checkType: 'in',
    dataSource: 'user',
    dataKey: 'orgId',
    label: '机构数据权限限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'orgIds',
    label: '职工数据权限限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    dataSource: 'data',
    label: '指定数据限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'roleIds',
    label: '指定角色限制',
    checkDataSource: 'value',
    selectData: roleList
  },
  {
    checkType: 'in',
    dataSource: 'user',
    dataKey: 'userId',
    label: '指定用户限制',
    checkDataSource: 'value',
    selectData: userList
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'workerIds',
    label: '指定员工限制',
    checkDataSource: 'value',
    selectData: workerList
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'orgIds',
    label: '指定机构限制',
    checkDataSource: 'value',
    selectData: orgList
  }]
}

export const checkTypeList = [{
  key: '==',
  label: '相等'
},
{
  key: 'in',
  label: '在匹配值中'
},
{
  key: 'mix',
  label: '有交集'
}]

export const sourceDataList = [{
  key: 'data',
  label: '数据'
},
{
  key: 'user',
  label: '用户信息'
},
{
  key: 'value',
  label: '指定值'
}]

export const getDataSourceKeysConfig = (statusGroupList, applyTypeList) => {
  let statusGroupMap = {}
  statusGroupList.forEach(v => {
    statusGroupMap[v.groupKey] = v
  })
  let data = {
    user: [{
      key: 'userId',
      label: '用户',
      tag: 'user'
    },
    {
      key: 'roleId',
      label: '角色',
      tag: 'role'
    },
    {
      key: 'workerId',
      label: '职工',
      tag: 'worker'
    },
    {
      key: 'orgId',
      label: '机构',
      tag: 'org'
    }],
    askLeaveApply: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.askLeaveApply.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'applyType',
      label: '流程类型',
      selectData: applyTypeList
    },
    {
      key: 'orgId',
      label: '职工所属科室',
      tag: 'org'
    },
    {
      key: 'workerId',
      label: '请假员工',
      tag: 'worker'
    },
    {
      key: 'orgLeadUserId',
      label: '科主任',
      tag: 'user'
    },
    {
      key: 'fgLeadUserId',
      label: '部门分管领导',
      tag: 'user'
    },
    {
      key: 'personnelUserId',
      label: '人事部门领导',
      tag: 'user'
    },
    {
      key: 'associateDeanUserId',
      label: '副院长',
      tag: 'user'
    },
    {
      key: 'deanUserId',
      label: '院长',
      tag: 'user'
    }],
    officialDocumentSend: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.officialDocumentSend.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'userId',
      label: '新增用户',
      tag: 'user'
    },
    {
      key: 'orgId',
      label: '发文机构',
      tag: 'org'
    },
    {
      key: 'leadUserId',
      label: '分管领导',
      tag: 'user'
    },
    {
      key: 'deanUserId',
      label: '院长',
      tag: 'user'
    }]
  }
  return data
}